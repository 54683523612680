/**
 * F.R.I.D.A.Y.
 * Female Replacement Intelligent Digital Assistant Youth
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AilimeCategorieMateriePrimeBean } from '../model/ailimeCategorieMateriePrimeBean';
import { AilimeMateriePrimeBean } from '../model/ailimeMateriePrimeBean';
import { Lotto2ProdBean } from '../model/lotto2ProdBean';
import { LottoBean } from '../model/lottoBean';
import { LottoElementoBean } from '../model/lottoElementoBean';
import { RequestLottoBean } from '../model/requestLottoBean';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LottoControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get all categorie materie prime
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCategorieMaterieUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<AilimeCategorieMateriePrimeBean>>;
    public getAllCategorieMaterieUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AilimeCategorieMateriePrimeBean>>>;
    public getAllCategorieMaterieUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AilimeCategorieMateriePrimeBean>>>;
    public getAllCategorieMaterieUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<AilimeCategorieMateriePrimeBean>>(`${this.basePath}/api/ailime/lotti/getAllCategorieMaterie`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Lotti
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllLottiUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<LottoBean>>;
    public getAllLottiUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LottoBean>>>;
    public getAllLottiUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LottoBean>>>;
    public getAllLottiUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<LottoBean>>(`${this.basePath}/api/ailime/lotti/getAllLotti`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all materie prime
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllMateriePrimeUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<AilimeMateriePrimeBean>>;
    public getAllMateriePrimeUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AilimeMateriePrimeBean>>>;
    public getAllMateriePrimeUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AilimeMateriePrimeBean>>>;
    public getAllMateriePrimeUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<AilimeMateriePrimeBean>>(`${this.basePath}/api/ailime/lotti/getAllMateriePrime`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Elementi Per Lotto
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getElementiPerLottoUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<LottoElementoBean>>;
    public getElementiPerLottoUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LottoElementoBean>>>;
    public getElementiPerLottoUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LottoElementoBean>>>;
    public getElementiPerLottoUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<LottoElementoBean>>(`${this.basePath}/api/ailime/lotti/getElementiPerLotto`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get lotto by id
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLottoByIdUsingPOST(bean: RequestLottoBean, observe?: 'body', reportProgress?: boolean): Observable<LottoBean>;
    public getLottoByIdUsingPOST(bean: RequestLottoBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LottoBean>>;
    public getLottoByIdUsingPOST(bean: RequestLottoBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LottoBean>>;
    public getLottoByIdUsingPOST(bean: RequestLottoBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getLottoByIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LottoBean>(`${this.basePath}/api/ailime/lotti/getLottoById`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save categorie materie
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveCategorieMaterieUsingPOST(bean: AilimeCategorieMateriePrimeBean, observe?: 'body', reportProgress?: boolean): Observable<AilimeCategorieMateriePrimeBean>;
    public saveCategorieMaterieUsingPOST(bean: AilimeCategorieMateriePrimeBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AilimeCategorieMateriePrimeBean>>;
    public saveCategorieMaterieUsingPOST(bean: AilimeCategorieMateriePrimeBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AilimeCategorieMateriePrimeBean>>;
    public saveCategorieMaterieUsingPOST(bean: AilimeCategorieMateriePrimeBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling saveCategorieMaterieUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AilimeCategorieMateriePrimeBean>(`${this.basePath}/api/ailime/lotti/saveCategorieMaterie`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save descrizioni
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveDescrizioniUsingPOST(bean: Lotto2ProdBean, observe?: 'body', reportProgress?: boolean): Observable<Lotto2ProdBean>;
    public saveDescrizioniUsingPOST(bean: Lotto2ProdBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Lotto2ProdBean>>;
    public saveDescrizioniUsingPOST(bean: Lotto2ProdBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Lotto2ProdBean>>;
    public saveDescrizioniUsingPOST(bean: Lotto2ProdBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling saveDescrizioniUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Lotto2ProdBean>(`${this.basePath}/api/ailime/lotti/saveDescrizioni`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save lotto
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveLottoUsingPOST(bean: LottoBean, observe?: 'body', reportProgress?: boolean): Observable<LottoBean>;
    public saveLottoUsingPOST(bean: LottoBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LottoBean>>;
    public saveLottoUsingPOST(bean: LottoBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LottoBean>>;
    public saveLottoUsingPOST(bean: LottoBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling saveLottoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LottoBean>(`${this.basePath}/api/ailime/lotti/saveLotto`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save materia prima
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveMateriaPrimaUsingPOST(bean: AilimeMateriePrimeBean, observe?: 'body', reportProgress?: boolean): Observable<AilimeMateriePrimeBean>;
    public saveMateriaPrimaUsingPOST(bean: AilimeMateriePrimeBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AilimeMateriePrimeBean>>;
    public saveMateriaPrimaUsingPOST(bean: AilimeMateriePrimeBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AilimeMateriePrimeBean>>;
    public saveMateriaPrimaUsingPOST(bean: AilimeMateriePrimeBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling saveMateriaPrimaUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AilimeMateriePrimeBean>(`${this.basePath}/api/ailime/lotti/saveMateriaPrima`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
