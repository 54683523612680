export * from './amazonController.service';
import { AmazonControllerService } from './amazonController.service';
export * from './authenticationController.service';
import { AuthenticationControllerService } from './authenticationController.service';
export * from './brtController.service';
import { BrtControllerService } from './brtController.service';
export * from './catalogoController.service';
import { CatalogoControllerService } from './catalogoController.service';
export * from './colloController.service';
import { ColloControllerService } from './colloController.service';
export * from './colloTrackerController.service';
import { ColloTrackerControllerService } from './colloTrackerController.service';
export * from './dashboardController.service';
import { DashboardControllerService } from './dashboardController.service';
export * from './downloadController.service';
import { DownloadControllerService } from './downloadController.service';
export * from './feedController.service';
import { FeedControllerService } from './feedController.service';
export * from './glsController.service';
import { GlsControllerService } from './glsController.service';
export * from './importOrdiniController.service';
import { ImportOrdiniControllerService } from './importOrdiniController.service';
export * from './lottoController.service';
import { LottoControllerService } from './lottoController.service';
export * from './magazzinoAilimeController.service';
import { MagazzinoAilimeControllerService } from './magazzinoAilimeController.service';
export * from './magazzinoController.service';
import { MagazzinoControllerService } from './magazzinoController.service';
export * from './mailController.service';
import { MailControllerService } from './mailController.service';
export * from './manoManoController.service';
import { ManoManoControllerService } from './manoManoController.service';
import { MiraklControllerService } from './miraklController.service';
export * from './ordineController.service';
import { MetroControllerService } from './metroController.service';
import { OrdineControllerService } from './ordineController.service';
export * from './printerController.service';
import { PrinterControllerService } from './printerController.service';
export * from './sospesiController.service';
import { SospesiControllerService } from './sospesiController.service';
export * from './statisticheController.service';
import { StatisticheControllerService } from './statisticheController.service';
export * from './testMailController.service';
import { TestMailControllerService } from './testMailController.service';
export * from './tntController.service';
import { TntControllerService } from './tntController.service';
export const APIS = [AmazonControllerService, AuthenticationControllerService, BrtControllerService, CatalogoControllerService, ColloControllerService, ColloTrackerControllerService, DashboardControllerService, DownloadControllerService, FeedControllerService, GlsControllerService, ImportOrdiniControllerService, LottoControllerService, MagazzinoAilimeControllerService, MagazzinoControllerService, MailControllerService, ManoManoControllerService, OrdineControllerService, PrinterControllerService, SospesiControllerService, StatisticheControllerService, TestMailControllerService, TntControllerService,MiraklControllerService];
