/**
 * F.R.I.D.A.Y.
 * Female Replacement Intelligent Digital Assistant Youth
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { GenericResultBean } from '../model/genericResultBean';


@Injectable()
export class MetroControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Acquisizione ordini metro
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acquireOrdiniMetroUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public acquireOrdiniMetroUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public acquireOrdiniMetroUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public acquireOrdiniMetroUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/ailime/ordine/metro/acquireOrdiniMetro`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Import ordini metro
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public importOrdiniMetroUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
        public importOrdiniMetroUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
        public importOrdiniMetroUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
        public importOrdiniMetroUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
            let headers = this.defaultHeaders;
    
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json'
            ];
    
            return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/ordine/metro/importOrdiniMetro`,
                null,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }


    /**
     * Set tracking metro
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setTrackingMetroUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public setTrackingMetroUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public setTrackingMetroUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public setTrackingMetroUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/ordine/metro/setTrackingMetro`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }   

    /**
     * Send invoice metro
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public sendInvoiceMetroUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
        public sendInvoiceMetroUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
        public sendInvoiceMetroUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
        public sendInvoiceMetroUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
            let headers = this.defaultHeaders;
    
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json'
            ];
    
            return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/ordine/metro/sendInvoiceMetro`,
                null,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }
    

}
