import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef} from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'conferma-dialog',
  templateUrl: './conferma-dialog.component.html',
  styleUrls: ['./conferma-dialog.component.scss']
})
export class ConfermaDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfermaDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
