import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AmazonControllerService } from './api/amazonController.service';
import { AuthenticationControllerService } from './api/authenticationController.service';
import { BrtControllerService } from './api/brtController.service';
import { CatalogoControllerService } from './api/catalogoController.service';
import { ColloControllerService } from './api/colloController.service';
import { ColloTrackerControllerService } from './api/colloTrackerController.service';
import { DashboardControllerService } from './api/dashboardController.service';
import { DownloadControllerService } from './api/downloadController.service';
import { FeedControllerService } from './api/feedController.service';
import { GlsControllerService } from './api/glsController.service';
import { ImportOrdiniControllerService } from './api/importOrdiniController.service';
import { LottoControllerService } from './api/lottoController.service';
import { MagazzinoAilimeControllerService } from './api/magazzinoAilimeController.service';
import { MagazzinoControllerService } from './api/magazzinoController.service';
import { MailControllerService } from './api/mailController.service';
import { ManoManoControllerService } from './api/manoManoController.service';
import { OrdineControllerService } from './api/ordineController.service';
import { PrinterControllerService } from './api/printerController.service';
import { SospesiControllerService } from './api/sospesiController.service';
import { StatisticheControllerService } from './api/statisticheController.service';
import { TestMailControllerService } from './api/testMailController.service';
import { TntControllerService } from './api/tntController.service';
import { BricoBravoControllerService } from './api/bricoBravoController.service';
import { MiraklControllerService } from './api/miraklController.service';
import { METHODS } from 'http';
import { MetroControllerService } from './api/metroController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AmazonControllerService,
    AuthenticationControllerService,
    BrtControllerService,
    CatalogoControllerService,
    ColloControllerService,
    ColloTrackerControllerService,
    DashboardControllerService,
    DownloadControllerService,
    FeedControllerService,
    GlsControllerService,
    ImportOrdiniControllerService,
    LottoControllerService,
    MagazzinoAilimeControllerService,
    MagazzinoControllerService,
    MailControllerService,
    ManoManoControllerService,
    BricoBravoControllerService,
    OrdineControllerService,
    PrinterControllerService,
    SospesiControllerService,
    StatisticheControllerService,
    TestMailControllerService,
    TntControllerService ,
    MiraklControllerService,
    MetroControllerService]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
