import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FonteBean, StatoOrdineBean, OrdineControllerService, MetodoPagamentoBean, CorriereBean } from 'app/api-friday';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private ordineController: OrdineControllerService, private dialog: MatDialog) { }

  /* Fonti */
  public fontiListSource = new BehaviorSubject<FonteBean[]>(undefined);
  currentFontiList = this.fontiListSource.asObservable();

  /* StatiOrdine */
  public statiOrdineListSource = new BehaviorSubject<StatoOrdineBean[]>(undefined);
  currentStatiOrdineList = this.statiOrdineListSource.asObservable();

  /* Corrieri */
  public corrieriListSource = new BehaviorSubject<CorriereBean[]>(undefined);
  currentCorrieriList = this.corrieriListSource.asObservable();

  /* Pagamenti */
  public pagamentiListSource = new BehaviorSubject<MetodoPagamentoBean[]>(undefined);
  currentPagamentiList = this.pagamentiListSource.asObservable();

  loadConfig() {

    this.ordineController.getAllCorriereUsingPOST().subscribe(result => {
      if (result) {
        this.corrieriListSource.next(result);
      }
    },
      error => {
        console.log('errore caricamento corrieri', error);
      }
    );

    this.ordineController.getAllFonteUsingPOST().subscribe(result => {
      if (result) {
        this.fontiListSource.next(result);
      }
    },
      error => {
        console.log('errore caricamento fonti', error);
      }
    );

    this.ordineController.getAllStatoOrdineUsingPOST().subscribe(result => {
      if (result) {
        this.statiOrdineListSource.next(result);
      }
    },
      error => {
        console.log('errore caricamento stati ordine', error);
      }
    );

    this.ordineController.getAllMetodoPagamentoUsingPOST().subscribe(result => {
      if (result) {
        this.pagamentiListSource.next(result);
      }
    },
      error => {
        console.log('errore caricamento pagamenti', error);
      }
    );

  }

}
