/**
 * F.R.I.D.A.Y.
 * Female Replacement Intelligent Digital Assistant Youth
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Fornitura2stockBean } from '../model/fornitura2stockBean';
import { GenericResultBean } from '../model/genericResultBean';
import { InputStreamResource } from '../model/inputStreamResource';
import { PrintResultBean } from '../model/printResultBean';
import { RequestPrintBean } from '../model/requestPrintBean';
import { RequestPrintMonacoLabelBean } from '../model/requestPrintMonacoLabelBean';
import { RequestPrintMonacoLabelUfficioBean } from '../model/requestPrintMonacoLabelUfficioBean';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PrinterControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * download nota fornitura
     * 
     * @param idFornitura idFornitura
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadNotaFornituraUsingGET(idFornitura: number, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadNotaFornituraUsingGET(idFornitura: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadNotaFornituraUsingGET(idFornitura: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadNotaFornituraUsingGET(idFornitura: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idFornitura === null || idFornitura === undefined) {
            throw new Error('Required parameter idFornitura was null or undefined when calling downloadNotaFornituraUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idFornitura !== undefined && idFornitura !== null) {
            queryParameters = queryParameters.set('idFornitura', <any>idFornitura);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<InputStreamResource>(`${this.basePath}/api/ailime/collo/downloadNotaMagazzino`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * download tessili f2s
     * 
     * @param idF2s idF2s
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTessiliF2sUsingGET(idF2s: number, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadTessiliF2sUsingGET(idF2s: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadTessiliF2sUsingGET(idF2s: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadTessiliF2sUsingGET(idF2s: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idF2s === null || idF2s === undefined) {
            throw new Error('Required parameter idF2s was null or undefined when calling downloadTessiliF2sUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idF2s !== undefined && idF2s !== null) {
            queryParameters = queryParameters.set('idF2s', <any>idF2s);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<InputStreamResource>(`${this.basePath}/api/ailime/collo/downloadTessiliF2s`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * free print epl ufficio
     * 
     * @param printInfo printInfo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public freePrintEPLUfficioUsingPOST(printInfo: RequestPrintBean, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public freePrintEPLUfficioUsingPOST(printInfo: RequestPrintBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public freePrintEPLUfficioUsingPOST(printInfo: RequestPrintBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public freePrintEPLUfficioUsingPOST(printInfo: RequestPrintBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (printInfo === null || printInfo === undefined) {
            throw new Error('Required parameter printInfo was null or undefined when calling freePrintEPLUfficioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/collo/freePrintEPLUfficio`,
            printInfo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * free print magazzino
     * 
     * @param printInfo printInfo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public freePrintMagazzinoUsingPOST(printInfo: RequestPrintBean, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public freePrintMagazzinoUsingPOST(printInfo: RequestPrintBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public freePrintMagazzinoUsingPOST(printInfo: RequestPrintBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public freePrintMagazzinoUsingPOST(printInfo: RequestPrintBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (printInfo === null || printInfo === undefined) {
            throw new Error('Required parameter printInfo was null or undefined when calling freePrintMagazzinoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/collo/freePrintMagazzino`,
            printInfo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * free print produzione
     * 
     * @param printInfo printInfo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public freePrintProduzioneUsingPOST(printInfo: RequestPrintBean, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public freePrintProduzioneUsingPOST(printInfo: RequestPrintBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public freePrintProduzioneUsingPOST(printInfo: RequestPrintBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public freePrintProduzioneUsingPOST(printInfo: RequestPrintBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (printInfo === null || printInfo === undefined) {
            throw new Error('Required parameter printInfo was null or undefined when calling freePrintProduzioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/collo/freePrintProduzione`,
            printInfo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * free print ufficio
     * 
     * @param printInfo printInfo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public freePrintUfficioUsingPOST(printInfo: RequestPrintBean, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public freePrintUfficioUsingPOST(printInfo: RequestPrintBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public freePrintUfficioUsingPOST(printInfo: RequestPrintBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public freePrintUfficioUsingPOST(printInfo: RequestPrintBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (printInfo === null || printInfo === undefined) {
            throw new Error('Required parameter printInfo was null or undefined when calling freePrintUfficioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/collo/freePrintUfficio`,
            printInfo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * print and save f2s label
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printAndSaveF2sLabelUsingPOST(bean: Fornitura2stockBean, observe?: 'body', reportProgress?: boolean): Observable<PrintResultBean>;
    public printAndSaveF2sLabelUsingPOST(bean: Fornitura2stockBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PrintResultBean>>;
    public printAndSaveF2sLabelUsingPOST(bean: Fornitura2stockBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PrintResultBean>>;
    public printAndSaveF2sLabelUsingPOST(bean: Fornitura2stockBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling printAndSaveF2sLabelUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PrintResultBean>(`${this.basePath}/api/ailime/collo/printAndSaveF2sLabel`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * print DPD magazzino ufficio
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printDpdMagazzinoUfficioUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<PrintResultBean>;
    public printDpdMagazzinoUfficioUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PrintResultBean>>;
    public printDpdMagazzinoUfficioUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PrintResultBean>>;
    public printDpdMagazzinoUfficioUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PrintResultBean>(`${this.basePath}/api/ailime/collo/printDpdMagazzinoUfficio`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * print monaco label ufficio
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printMonacoLabelUfficioUsingPOST(bean: RequestPrintMonacoLabelUfficioBean, observe?: 'body', reportProgress?: boolean): Observable<PrintResultBean>;
    public printMonacoLabelUfficioUsingPOST(bean: RequestPrintMonacoLabelUfficioBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PrintResultBean>>;
    public printMonacoLabelUfficioUsingPOST(bean: RequestPrintMonacoLabelUfficioBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PrintResultBean>>;
    public printMonacoLabelUfficioUsingPOST(bean: RequestPrintMonacoLabelUfficioBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling printMonacoLabelUfficioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PrintResultBean>(`${this.basePath}/api/ailime/collo/printMonacoLabelUfficio`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * print monaco label
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printMonacoLabelUsingPOST(bean: RequestPrintMonacoLabelBean, observe?: 'body', reportProgress?: boolean): Observable<PrintResultBean>;
    public printMonacoLabelUsingPOST(bean: RequestPrintMonacoLabelBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PrintResultBean>>;
    public printMonacoLabelUsingPOST(bean: RequestPrintMonacoLabelBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PrintResultBean>>;
    public printMonacoLabelUsingPOST(bean: RequestPrintMonacoLabelBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling printMonacoLabelUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PrintResultBean>(`${this.basePath}/api/ailime/collo/printMonacoLabel`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
