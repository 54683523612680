/**
 * F.R.I.D.A.Y.
 * Female Replacement Intelligent Digital Assistant Youth
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { IbimCategoriaBean } from '../model/ibimCategoriaBean';
import { IbimMagazzinoBean } from '../model/ibimMagazzinoBean';
import { IbimProdottoBean } from '../model/ibimProdottoBean';
import { IbimStoreBean } from '../model/ibimStoreBean';
import { IbimTipoAttributoBean } from '../model/ibimTipoAttributoBean';
import { RecordProdottoBean } from '../model/recordProdottoBean';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MagazzinoControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get All Store
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllStoresUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<IbimStoreBean>>;
    public getAllStoresUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IbimStoreBean>>>;
    public getAllStoresUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IbimStoreBean>>>;
    public getAllStoresUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<IbimStoreBean>>(`${this.basePath}/api/ibim/magazzino/getAllStores`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Tipi Attributo
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllTipiAttributoUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<IbimTipoAttributoBean>>;
    public getAllTipiAttributoUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IbimTipoAttributoBean>>>;
    public getAllTipiAttributoUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IbimTipoAttributoBean>>>;
    public getAllTipiAttributoUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<IbimTipoAttributoBean>>(`${this.basePath}/api/ibim/magazzino/getAllTipiAttributo`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Categorie by IdStore
     * 
     * @param idStore idStore
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCategorieByIdStoreUsingPOST(idStore: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IbimCategoriaBean>>;
    public getCategorieByIdStoreUsingPOST(idStore: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IbimCategoriaBean>>>;
    public getCategorieByIdStoreUsingPOST(idStore: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IbimCategoriaBean>>>;
    public getCategorieByIdStoreUsingPOST(idStore: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idStore === null || idStore === undefined) {
            throw new Error('Required parameter idStore was null or undefined when calling getCategorieByIdStoreUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<IbimCategoriaBean>>(`${this.basePath}/api/ibim/magazzino/getCategorieByIdStore`,
            idStore,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Magazzini by IdStore
     * 
     * @param idStore idStore
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMagazziniByIdStoreUsingPOST(idStore: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IbimMagazzinoBean>>;
    public getMagazziniByIdStoreUsingPOST(idStore: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IbimMagazzinoBean>>>;
    public getMagazziniByIdStoreUsingPOST(idStore: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IbimMagazzinoBean>>>;
    public getMagazziniByIdStoreUsingPOST(idStore: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idStore === null || idStore === undefined) {
            throw new Error('Required parameter idStore was null or undefined when calling getMagazziniByIdStoreUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<IbimMagazzinoBean>>(`${this.basePath}/api/ibim/magazzino/getMagazziniByIdStore`,
            idStore,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Prodotti by IdStore
     * 
     * @param idStore idStore
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProdottiByIdStoreUsingPOST(idStore: number, observe?: 'body', reportProgress?: boolean): Observable<Array<RecordProdottoBean>>;
    public getProdottiByIdStoreUsingPOST(idStore: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RecordProdottoBean>>>;
    public getProdottiByIdStoreUsingPOST(idStore: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RecordProdottoBean>>>;
    public getProdottiByIdStoreUsingPOST(idStore: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idStore === null || idStore === undefined) {
            throw new Error('Required parameter idStore was null or undefined when calling getProdottiByIdStoreUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<RecordProdottoBean>>(`${this.basePath}/api/ibim/magazzino/getProdottiByIdStore`,
            idStore,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Prodotto By Id
     * 
     * @param idProdotto idProdotto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProdottoByIdUsingPOST(idProdotto: number, observe?: 'body', reportProgress?: boolean): Observable<IbimProdottoBean>;
    public getProdottoByIdUsingPOST(idProdotto: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IbimProdottoBean>>;
    public getProdottoByIdUsingPOST(idProdotto: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IbimProdottoBean>>;
    public getProdottoByIdUsingPOST(idProdotto: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProdotto === null || idProdotto === undefined) {
            throw new Error('Required parameter idProdotto was null or undefined when calling getProdottoByIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IbimProdottoBean>(`${this.basePath}/api/ibim/magazzino/getProdottoById`,
            idProdotto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save Categoria
     * 
     * @param categoria categoria
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveCategoriaUsingPOST(categoria: IbimCategoriaBean, observe?: 'body', reportProgress?: boolean): Observable<IbimCategoriaBean>;
    public saveCategoriaUsingPOST(categoria: IbimCategoriaBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IbimCategoriaBean>>;
    public saveCategoriaUsingPOST(categoria: IbimCategoriaBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IbimCategoriaBean>>;
    public saveCategoriaUsingPOST(categoria: IbimCategoriaBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoria === null || categoria === undefined) {
            throw new Error('Required parameter categoria was null or undefined when calling saveCategoriaUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IbimCategoriaBean>(`${this.basePath}/api/ibim/magazzino/saveCategoria`,
            categoria,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save Magazzino
     * 
     * @param magazzino magazzino
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveMagazzinoUsingPOST(magazzino: IbimMagazzinoBean, observe?: 'body', reportProgress?: boolean): Observable<IbimMagazzinoBean>;
    public saveMagazzinoUsingPOST(magazzino: IbimMagazzinoBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IbimMagazzinoBean>>;
    public saveMagazzinoUsingPOST(magazzino: IbimMagazzinoBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IbimMagazzinoBean>>;
    public saveMagazzinoUsingPOST(magazzino: IbimMagazzinoBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (magazzino === null || magazzino === undefined) {
            throw new Error('Required parameter magazzino was null or undefined when calling saveMagazzinoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IbimMagazzinoBean>(`${this.basePath}/api/ibim/magazzino/saveMagazzino`,
            magazzino,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save Prodotto
     * 
     * @param prodotto prodotto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveProdottoUsingPOST(prodotto: IbimProdottoBean, observe?: 'body', reportProgress?: boolean): Observable<IbimMagazzinoBean>;
    public saveProdottoUsingPOST(prodotto: IbimProdottoBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IbimMagazzinoBean>>;
    public saveProdottoUsingPOST(prodotto: IbimProdottoBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IbimMagazzinoBean>>;
    public saveProdottoUsingPOST(prodotto: IbimProdottoBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (prodotto === null || prodotto === undefined) {
            throw new Error('Required parameter prodotto was null or undefined when calling saveProdottoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IbimMagazzinoBean>(`${this.basePath}/api/ibim/magazzino/saveProdotto`,
            prodotto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save TipoAttributo
     * 
     * @param tipoAttributo tipoAttributo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveTipoAttributoUsingPOST(tipoAttributo: IbimTipoAttributoBean, observe?: 'body', reportProgress?: boolean): Observable<IbimTipoAttributoBean>;
    public saveTipoAttributoUsingPOST(tipoAttributo: IbimTipoAttributoBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IbimTipoAttributoBean>>;
    public saveTipoAttributoUsingPOST(tipoAttributo: IbimTipoAttributoBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IbimTipoAttributoBean>>;
    public saveTipoAttributoUsingPOST(tipoAttributo: IbimTipoAttributoBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tipoAttributo === null || tipoAttributo === undefined) {
            throw new Error('Required parameter tipoAttributo was null or undefined when calling saveTipoAttributoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IbimTipoAttributoBean>(`${this.basePath}/api/ibim/magazzino/saveTipoAttributo`,
            tipoAttributo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
