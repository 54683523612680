import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationControllerService, OperatoreBean } from 'app/api-friday';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<OperatoreBean>;
    public currentUser: Observable<OperatoreBean>;

    constructor(private http: HttpClient,
        private authController: AuthenticationControllerService,
        private router: Router) {
        this.currentUserSubject = new BehaviorSubject<OperatoreBean>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): OperatoreBean {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string, returnUrl: string) {
        let operatore : OperatoreBean = {};
        operatore.username = username;
        operatore.password = password;
        this.authController.authenticateUsingPOST(operatore).subscribe(operatore => {
            // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
            operatore.authdata = window.btoa(username + ':' + password);
            localStorage.setItem('currentUser', JSON.stringify(operatore));
            this.currentUserSubject.next(operatore);

            this.router.navigate([returnUrl]);
        }, error => {
            console.log('Errore', error);
        });

    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        delete this.currentUser;
        this.router.navigate(['/login'])
    }
}