/**
 * F.R.I.D.A.Y.
 * Female Replacement Intelligent Digital Assistant Youth
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { BancaleBean } from '../model/bancaleBean';
import { BancaleFlatBean } from '../model/bancaleFlatBean';
import { BinarioBean } from '../model/binarioBean';
import { ColloBean } from '../model/colloBean';
import { ColloTrackerBean } from '../model/colloTrackerBean';
import { DamageBean } from '../model/damageBean';
import { DamageFlatBean } from '../model/damageFlatBean';
import { DestinatarioFornituraBean } from '../model/destinatarioFornituraBean';
import { FornituraAmazon2stockBean } from '../model/fornituraAmazon2stockBean';
import { FornituraAmazonBean } from '../model/fornituraAmazonBean';
import { FornituraBean } from '../model/fornituraBean';
import { GenericResultBean } from '../model/genericResultBean';
import { InputStreamResource } from '../model/inputStreamResource';
import { MotivazioneDamageBean } from '../model/motivazioneDamageBean';
import { Orders2productBean } from '../model/orders2productBean';
import { PrintResultBean } from '../model/printResultBean';
import { ProductType2CharacteristicsBean } from '../model/productType2CharacteristicsBean';
import { ProductTypeBean } from '../model/productTypeBean';
import { RequestBancaleBean } from '../model/requestBancaleBean';
import { RequestBancaleForViaggioBean } from '../model/requestBancaleForViaggioBean';
import { RequestBinarioBean } from '../model/requestBinarioBean';
import { RequestColloBancaleBean } from '../model/requestColloBancaleBean';
import { RequestOutgoingProductBean } from '../model/requestOutgoingProductBean';
import { RequestProd2CahrsBean } from '../model/requestProd2CahrsBean';
import { RequestRicercaProdottoMagazzinoBean } from '../model/requestRicercaProdottoMagazzinoBean';
import { RequestRollbackBean } from '../model/requestRollbackBean';
import { RequestSaveDamageCollectionBean } from '../model/requestSaveDamageCollectionBean';
import { RequestStock2ProductdBean } from '../model/requestStock2ProductdBean';
import { RequestUpdateBancalexF2sBean } from '../model/requestUpdateBancalexF2sBean';
import { ResponseGetCorrieriCaricabiliBean } from '../model/responseGetCorrieriCaricabiliBean';
import { ResultBanGior2CaricoBean } from '../model/resultBanGior2CaricoBean';
import { ResultBancaleForViaggioBean } from '../model/resultBancaleForViaggioBean';
import { ResultCatalogoBean } from '../model/resultCatalogoBean';
import { ResultCensimentoStock2ProductBean } from '../model/resultCensimentoStock2ProductBean';
import { ResultPTypePClassS2ProcuctBean } from '../model/resultPTypePClassS2ProcuctBean';
import { ResultProdType2Chars2propBean } from '../model/resultProdType2Chars2propBean';
import { ResultProdTypeClassBean } from '../model/resultProdTypeClassBean';
import { ResultUpdateBancaleBean } from '../model/resultUpdateBancaleBean';
import { ResultUpdateDamageCollectionBean } from '../model/resultUpdateDamageCollectionBean';
import { ResultUpdateViaggioBean } from '../model/resultUpdateViaggioBean';
import { ResultWarehouseOutgoingProductsBean } from '../model/resultWarehouseOutgoingProductsBean';
import { RiepilogoBancaliByViaggioBean } from '../model/riepilogoBancaliByViaggioBean';
import { Stock2ProductdBean } from '../model/stock2ProductdBean';
import { SwapBancaleBean } from '../model/swapBancaleBean';
import { ViaggioBean } from '../model/viaggioBean';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { OrdineBean } from '../model/ordineBean';
import { AccessorioBean } from '../model/accessorioBean';
import { FornituraBeanFlat } from '../model/fornituraBeanFlat';


@Injectable()
export class MagazzinoAilimeControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     * Assegna i corrieri agli ordini
     * 
     * @param magazzino magazzino
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assegnaCorriereUsingPOST(magazzino?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public assegnaCorriereUsingPOST(magazzino?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public assegnaCorriereUsingPOST(magazzino?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public assegnaCorriereUsingPOST(magazzino?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (magazzino !== undefined && magazzino !== null) {
            queryParameters = queryParameters.set('magazzino', <any>magazzino);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ibim/magazzino/assegnaCorrieri`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * Trasferisci ordini ai corrieri
     * 
     * @param corriere corriere
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trasferisciOrdiniAiCorrieriUsingPOST(corriere: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public trasferisciOrdiniAiCorrieriUsingPOST(corriere: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public trasferisciOrdiniAiCorrieriUsingPOST(corriere: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public trasferisciOrdiniAiCorrieriUsingPOST(corriere: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (corriere === null || corriere === undefined) {
            throw new Error('Required parameter corriere was null or undefined when calling trasferisciOrdiniAiCorrieriUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ibim/magazzino/trasferisciOrdiniAiCorrieri`,
            corriere,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Controllo disponibilità
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public controllaDisponibilitaUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
        public controllaDisponibilitaUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
        public controllaDisponibilitaUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
        public controllaDisponibilitaUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
            let headers = this.defaultHeaders;
    
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json'
            ];
    
            return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ibim/magazzino/controlloDisponibilita`,
                null,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }

    /**
     * Cerca prodotto in magazzino
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cercaProdottoInMagazzinoUsingPOST(bean: RequestRicercaProdottoMagazzinoBean, observe?: 'body', reportProgress?: boolean): Observable<Array<BancaleFlatBean>>;
    public cercaProdottoInMagazzinoUsingPOST(bean: RequestRicercaProdottoMagazzinoBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BancaleFlatBean>>>;
    public cercaProdottoInMagazzinoUsingPOST(bean: RequestRicercaProdottoMagazzinoBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BancaleFlatBean>>>;
    public cercaProdottoInMagazzinoUsingPOST(bean: RequestRicercaProdottoMagazzinoBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling cercaProdottoInMagazzinoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<BancaleFlatBean>>(`${this.basePath}/api/ibim/magazzino/cercaProdottoInMagazzino`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Elimina Binario
     * 
     * @param binario binario
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBinarioUsingPOST(binario: BinarioBean, observe?: 'body', reportProgress?: boolean): Observable<BancaleBean>;
    public deleteBinarioUsingPOST(binario: BinarioBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BancaleBean>>;
    public deleteBinarioUsingPOST(binario: BinarioBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BancaleBean>>;
    public deleteBinarioUsingPOST(binario: BinarioBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (binario === null || binario === undefined) {
            throw new Error('Required parameter binario was null or undefined when calling deleteBinarioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BancaleBean>(`${this.basePath}/api/ibim/magazzino/deleteBinario`,
            binario,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * downloadEtichetteAdesiveFornitura
     * 
     * @param idFornitura idFornitura
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadEtichetteAdesiveFornituraUsingPOST(idFornitura: number, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadEtichetteAdesiveFornituraUsingPOST(idFornitura: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadEtichetteAdesiveFornituraUsingPOST(idFornitura: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadEtichetteAdesiveFornituraUsingPOST(idFornitura: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idFornitura === null || idFornitura === undefined) {
            throw new Error('Required parameter idFornitura was null or undefined when calling downloadEtichetteAdesiveFornituraUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InputStreamResource>(`${this.basePath}/api/ibim/magazzino/downloadEtichetteAdesiveFornitura`,
            idFornitura,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all bancali 2 stock
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBancali2StockUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<BancaleBean>>;
    public getAllBancali2StockUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BancaleBean>>>;
    public getAllBancali2StockUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BancaleBean>>>;
    public getAllBancali2StockUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<BancaleBean>>(`${this.basePath}/api/ibim/magazzino/getAllBancali2Stock`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all bancali by viaggio
     * 
     * @param idViaggio idViaggio
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBancaliByViaggioUsingPOST(idViaggio: number, observe?: 'body', reportProgress?: boolean): Observable<Array<BancaleBean>>;
    public getAllBancaliByViaggioUsingPOST(idViaggio: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BancaleBean>>>;
    public getAllBancaliByViaggioUsingPOST(idViaggio: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BancaleBean>>>;
    public getAllBancaliByViaggioUsingPOST(idViaggio: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idViaggio === null || idViaggio === undefined) {
            throw new Error('Required parameter idViaggio was null or undefined when calling getAllBancaliByViaggioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<BancaleBean>>(`${this.basePath}/api/ibim/magazzino/getAllBancaliByViaggio`,
            idViaggio,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all bancali flat
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBancaliFlatUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<BancaleFlatBean>>;
    public getAllBancaliFlatUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BancaleFlatBean>>>;
    public getAllBancaliFlatUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BancaleFlatBean>>>;
    public getAllBancaliFlatUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<BancaleFlatBean>>(`${this.basePath}/api/ibim/magazzino/getAllBancaliFlat`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all bancali
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBancaliUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<BancaleBean>>;
    public getAllBancaliUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BancaleBean>>>;
    public getAllBancaliUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BancaleBean>>>;
    public getAllBancaliUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<BancaleBean>>(`${this.basePath}/api/ibim/magazzino/getAllBancali`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get binario slots available by asin
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBinSlotsByAsinUsingPOST(bean: RequestBinarioBean, observe?: 'body', reportProgress?: boolean): Observable<Array<BinarioBean>>;
    public getAllBinSlotsByAsinUsingPOST(bean: RequestBinarioBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BinarioBean>>>;
    public getAllBinSlotsByAsinUsingPOST(bean: RequestBinarioBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BinarioBean>>>;
    public getAllBinSlotsByAsinUsingPOST(bean: RequestBinarioBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getAllBinSlotsByAsinUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<BinarioBean>>(`${this.basePath}/api/ibim/magazzino/getAllBinSlotsByAsin`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all binari
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBinariUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<BinarioBean>>;
    public getAllBinariUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BinarioBean>>>;
    public getAllBinariUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BinarioBean>>>;
    public getAllBinariUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<BinarioBean>>(`${this.basePath}/api/ibim/magazzino/getAllBinari`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get characteristics by prodtype
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCharacteristicsUsingPOST(bean: RequestProd2CahrsBean, observe?: 'body', reportProgress?: boolean): Observable<Array<ProductType2CharacteristicsBean>>;
    public getAllCharacteristicsUsingPOST(bean: RequestProd2CahrsBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProductType2CharacteristicsBean>>>;
    public getAllCharacteristicsUsingPOST(bean: RequestProd2CahrsBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProductType2CharacteristicsBean>>>;
    public getAllCharacteristicsUsingPOST(bean: RequestProd2CahrsBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getAllCharacteristicsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ProductType2CharacteristicsBean>>(`${this.basePath}/api/ibim/magazzino/getCharacteristicsByProducttype`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all damage flat
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDamageFlatUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<DamageFlatBean>>;
    public getAllDamageFlatUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DamageFlatBean>>>;
    public getAllDamageFlatUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DamageFlatBean>>>;
    public getAllDamageFlatUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<DamageFlatBean>>(`${this.basePath}/api/ibim/magazzino/getAllDamageFlat`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all damage
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDamageUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<DamageBean>>;
    public getAllDamageUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DamageBean>>>;
    public getAllDamageUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DamageBean>>>;
    public getAllDamageUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<DamageBean>>(`${this.basePath}/api/ibim/magazzino/getAllDamage`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all destinatari forniture
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDestinatariFornitureUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<DestinatarioFornituraBean>>;
    public getAllDestinatariFornitureUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DestinatarioFornituraBean>>>;
    public getAllDestinatariFornitureUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DestinatarioFornituraBean>>>;
    public getAllDestinatariFornitureUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<DestinatarioFornituraBean>>(`${this.basePath}/api/ibim/magazzino/getAllDestinatariForniture`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all empty binari
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllEmptyBinUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<BinarioBean>>;
    public getAllEmptyBinUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BinarioBean>>>;
    public getAllEmptyBinUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BinarioBean>>>;
    public getAllEmptyBinUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<BinarioBean>>(`${this.basePath}/api/ibim/magazzino/getAllEmptyBin`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all forniture amazon
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllFornitureAmazonUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<FornituraAmazonBean>>;
    public getAllFornitureAmazonUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FornituraAmazonBean>>>;
    public getAllFornitureAmazonUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FornituraAmazonBean>>>;
    public getAllFornitureAmazonUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<FornituraAmazonBean>>(`${this.basePath}/api/ibim/magazzino/getAllFornitureAmazon`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all forniture per magazzino
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllForniturePerMagazzinoUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<FornituraAmazonBean>>;
    public getAllForniturePerMagazzinoUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FornituraAmazonBean>>>;
    public getAllForniturePerMagazzinoUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FornituraAmazonBean>>>;
    public getAllForniturePerMagazzinoUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<FornituraAmazonBean>>(`${this.basePath}/api/ibim/magazzino/getAllForniturePerMagazzino`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all forniture
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllFornitureUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<FornituraBeanFlat>>;
    public getAllFornitureUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FornituraBeanFlat>>>;
    public getAllFornitureUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FornituraBeanFlat>>>;
    public getAllFornitureUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<FornituraBeanFlat>>(`${this.basePath}/api/ibim/magazzino/getAllForniture`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all motivazioni damage
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllMotivazioniDamageUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<MotivazioneDamageBean>>;
    public getAllMotivazioniDamageUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MotivazioneDamageBean>>>;
    public getAllMotivazioniDamageUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MotivazioneDamageBean>>>;
    public getAllMotivazioniDamageUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<MotivazioneDamageBean>>(`${this.basePath}/api/ibim/magazzino/getAllMotivazioniDamage`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all stock 2 product
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllStock2ProductUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<Stock2ProductdBean>>;
    public getAllStock2ProductUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Stock2ProductdBean>>>;
    public getAllStock2ProductUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Stock2ProductdBean>>>;
    public getAllStock2ProductUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<Stock2ProductdBean>>(`${this.basePath}/api/ibim/magazzino/getAllStock2Product`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all viaggi by stato
     * 
     * @param idStato idStato
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllViaggiByStatoUsingPOST(idStato: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ViaggioBean>>;
    public getAllViaggiByStatoUsingPOST(idStato: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ViaggioBean>>>;
    public getAllViaggiByStatoUsingPOST(idStato: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ViaggioBean>>>;
    public getAllViaggiByStatoUsingPOST(idStato: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idStato === null || idStato === undefined) {
            throw new Error('Required parameter idStato was null or undefined when calling getAllViaggiByStatoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ViaggioBean>>(`${this.basePath}/api/ibim/magazzino/getAllViaggiByStato`,
            idStato,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bancali e giornaliero da caricare
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBanColli2CaricoUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<ResultBanGior2CaricoBean>;
    public getBanColli2CaricoUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultBanGior2CaricoBean>>;
    public getBanColli2CaricoUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultBanGior2CaricoBean>>;
    public getBanColli2CaricoUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ResultBanGior2CaricoBean>(`${this.basePath}/api/ibim/magazzino/getBanColli2Carico`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bancali e giornaliero da scaricare
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBanColli2ScaricoUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<ResultBanGior2CaricoBean>;
    public getBanColli2ScaricoUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultBanGior2CaricoBean>>;
    public getBanColli2ScaricoUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultBanGior2CaricoBean>>;
    public getBanColli2ScaricoUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ResultBanGior2CaricoBean>(`${this.basePath}/api/ibim/magazzino/getBanColli2Scarico`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bancale by id
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBancaleByIdUsingPOST(bean: RequestBancaleBean, observe?: 'body', reportProgress?: boolean): Observable<BancaleBean>;
    public getBancaleByIdUsingPOST(bean: RequestBancaleBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BancaleBean>>;
    public getBancaleByIdUsingPOST(bean: RequestBancaleBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BancaleBean>>;
    public getBancaleByIdUsingPOST(bean: RequestBancaleBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getBancaleByIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BancaleBean>(`${this.basePath}/api/ibim/magazzino/getBancaleById`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get and bancale and set viaggio
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBancaleSetViaggioUsingPOST(request: RequestBancaleForViaggioBean, observe?: 'body', reportProgress?: boolean): Observable<ResultBancaleForViaggioBean>;
    public getBancaleSetViaggioUsingPOST(request: RequestBancaleForViaggioBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultBancaleForViaggioBean>>;
    public getBancaleSetViaggioUsingPOST(request: RequestBancaleForViaggioBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultBancaleForViaggioBean>>;
    public getBancaleSetViaggioUsingPOST(request: RequestBancaleForViaggioBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling getBancaleSetViaggioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResultBancaleForViaggioBean>(`${this.basePath}/api/ibim/magazzino/getBancaleSetViaggio`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bancali by asin
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBancaliByAsinUsingPOST(bean: RequestBancaleBean, observe?: 'body', reportProgress?: boolean): Observable<Array<BancaleBean>>;
    public getBancaliByAsinUsingPOST(bean: RequestBancaleBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BancaleBean>>>;
    public getBancaliByAsinUsingPOST(bean: RequestBancaleBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BancaleBean>>>;
    public getBancaliByAsinUsingPOST(bean: RequestBancaleBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getBancaliByAsinUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<BancaleBean>>(`${this.basePath}/api/ibim/magazzino/getBancaliByAsin`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bancali by id producttype
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBancaliByIdProducttypeUsingPOST(bean: RequestBancaleBean, observe?: 'body', reportProgress?: boolean): Observable<Array<BancaleBean>>;
    public getBancaliByIdProducttypeUsingPOST(bean: RequestBancaleBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BancaleBean>>>;
    public getBancaliByIdProducttypeUsingPOST(bean: RequestBancaleBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BancaleBean>>>;
    public getBancaliByIdProducttypeUsingPOST(bean: RequestBancaleBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getBancaliByIdProducttypeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<BancaleBean>>(`${this.basePath}/api/ibim/magazzino/getBancaliByIdProducttype`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bancali flat by asin
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBancaliFlatByAsinUsingPOST(bean: RequestBancaleBean, observe?: 'body', reportProgress?: boolean): Observable<Array<BancaleFlatBean>>;
    public getBancaliFlatByAsinUsingPOST(bean: RequestBancaleBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BancaleFlatBean>>>;
    public getBancaliFlatByAsinUsingPOST(bean: RequestBancaleBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BancaleFlatBean>>>;
    public getBancaliFlatByAsinUsingPOST(bean: RequestBancaleBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getBancaliFlatByAsinUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<BancaleFlatBean>>(`${this.basePath}/api/ibim/magazzino/getBancaliFlatByAsin`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bancali impegnati by asin
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBancaliImpegnatiByAsinUsingPOST(bean: RequestBancaleBean, observe?: 'body', reportProgress?: boolean): Observable<Array<BancaleBean>>;
    public getBancaliImpegnatiByAsinUsingPOST(bean: RequestBancaleBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BancaleBean>>>;
    public getBancaliImpegnatiByAsinUsingPOST(bean: RequestBancaleBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BancaleBean>>>;
    public getBancaliImpegnatiByAsinUsingPOST(bean: RequestBancaleBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getBancaliImpegnatiByAsinUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<BancaleBean>>(`${this.basePath}/api/ibim/magazzino/getBancaliImpegnatiByAsin`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bancali impegnati by id producttype
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBancaliImpegnatiByIdProducttypeUsingPOST(bean: RequestBancaleBean, observe?: 'body', reportProgress?: boolean): Observable<Array<BancaleBean>>;
    public getBancaliImpegnatiByIdProducttypeUsingPOST(bean: RequestBancaleBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BancaleBean>>>;
    public getBancaliImpegnatiByIdProducttypeUsingPOST(bean: RequestBancaleBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BancaleBean>>>;
    public getBancaliImpegnatiByIdProducttypeUsingPOST(bean: RequestBancaleBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getBancaliImpegnatiByIdProducttypeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<BancaleBean>>(`${this.basePath}/api/ibim/magazzino/getBancaliImpegnatiByIdProducttype`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bancali in stock by idBinario
     * 
     * @param idBinario idBinario
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBancaliStockByIdBinarioUsingPOST(idBinario: number, observe?: 'body', reportProgress?: boolean): Observable<Array<BancaleFlatBean>>;
    public getBancaliStockByIdBinarioUsingPOST(idBinario: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BancaleFlatBean>>>;
    public getBancaliStockByIdBinarioUsingPOST(idBinario: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BancaleFlatBean>>>;
    public getBancaliStockByIdBinarioUsingPOST(idBinario: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idBinario === null || idBinario === undefined) {
            throw new Error('Required parameter idBinario was null or undefined when calling getBancaliStockByIdBinarioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<BancaleFlatBean>>(`${this.basePath}/api/ibim/magazzino/getBancaliStockByIdBinario`,
            idBinario,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get binario by asin
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBinarioByAsinUsingPOST(bean: RequestBinarioBean, observe?: 'body', reportProgress?: boolean): Observable<Array<BinarioBean>>;
    public getBinarioByAsinUsingPOST(bean: RequestBinarioBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BinarioBean>>>;
    public getBinarioByAsinUsingPOST(bean: RequestBinarioBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BinarioBean>>>;
    public getBinarioByAsinUsingPOST(bean: RequestBinarioBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getBinarioByAsinUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<BinarioBean>>(`${this.basePath}/api/ibim/magazzino/getBinarioByAsin`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get binario by id
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBinarioByIdUsingPOST(bean: RequestBinarioBean, observe?: 'body', reportProgress?: boolean): Observable<BinarioBean>;
    public getBinarioByIdUsingPOST(bean: RequestBinarioBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BinarioBean>>;
    public getBinarioByIdUsingPOST(bean: RequestBinarioBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BinarioBean>>;
    public getBinarioByIdUsingPOST(bean: RequestBinarioBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getBinarioByIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BinarioBean>(`${this.basePath}/api/ibim/magazzino/getBinarioById`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get catalogo
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCatalogoUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<ResultCatalogoBean>;
    public getCatalogoUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultCatalogoBean>>;
    public getCatalogoUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultCatalogoBean>>;
    public getCatalogoUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ResultCatalogoBean>(`${this.basePath}/api/ibim/magazzino/getCatalogo`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get censimento stock2product
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCensimentoStock2ProductUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<ResultCensimentoStock2ProductBean>>;
    public getCensimentoStock2ProductUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResultCensimentoStock2ProductBean>>>;
    public getCensimentoStock2ProductUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResultCensimentoStock2ProductBean>>>;
    public getCensimentoStock2ProductUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<ResultCensimentoStock2ProductBean>>(`${this.basePath}/api/ibim/magazzino/getCensimentoStock2product`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get colli magazzino da caricare
     * 
     * @param corriere corriere
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getColliMagazzinoDaCaricareUsingPOST(corriere: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ColloBean>>;
    public getColliMagazzinoDaCaricareUsingPOST(corriere: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ColloBean>>>;
    public getColliMagazzinoDaCaricareUsingPOST(corriere: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ColloBean>>>;
    public getColliMagazzinoDaCaricareUsingPOST(corriere: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (corriere === null || corriere === undefined) {
            throw new Error('Required parameter corriere was null or undefined when calling getColliMagazzinoDaCaricareUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ColloBean>>(`${this.basePath}/api/ibim/magazzino/getColliMagazzinoDaCaricare`,
            corriere,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get colli produzione da caricare
     * 
     * @param corriere corriere
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getColliProduzioneDaCaricareUsingPOST(corriere: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ColloBean>>;
    public getColliProduzioneDaCaricareUsingPOST(corriere: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ColloBean>>>;
    public getColliProduzioneDaCaricareUsingPOST(corriere: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ColloBean>>>;
    public getColliProduzioneDaCaricareUsingPOST(corriere: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (corriere === null || corriere === undefined) {
            throw new Error('Required parameter corriere was null or undefined when calling getColliProduzioneDaCaricareUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ColloBean>>(`${this.basePath}/api/ibim/magazzino/getColliProduzioneDaCaricare`,
            corriere,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get collo bancale by order
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getColloBancaleByOrderUsingPOST(bean: RequestColloBancaleBean, observe?: 'body', reportProgress?: boolean): Observable<Array<ColloBean>>;
    public getColloBancaleByOrderUsingPOST(bean: RequestColloBancaleBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ColloBean>>>;
    public getColloBancaleByOrderUsingPOST(bean: RequestColloBancaleBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ColloBean>>>;
    public getColloBancaleByOrderUsingPOST(bean: RequestColloBancaleBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getColloBancaleByOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ColloBean>>(`${this.basePath}/api/ibim/magazzino/getColloBancaleByOrder`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get colloTracker 2 bancale
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getColloTracker2BancaleUsingPOST(bean: RequestColloBancaleBean, observe?: 'body', reportProgress?: boolean): Observable<Array<ColloTrackerBean>>;
    public getColloTracker2BancaleUsingPOST(bean: RequestColloBancaleBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ColloTrackerBean>>>;
    public getColloTracker2BancaleUsingPOST(bean: RequestColloBancaleBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ColloTrackerBean>>>;
    public getColloTracker2BancaleUsingPOST(bean: RequestColloBancaleBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getColloTracker2BancaleUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ColloTrackerBean>>(`${this.basePath}/api/ibim/magazzino/getColloTracker2Bancale`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get corrieri caricabili magazzino
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCorrieriCaricabiliMagazzinoUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<ResponseGetCorrieriCaricabiliBean>;
    public getCorrieriCaricabiliMagazzinoUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseGetCorrieriCaricabiliBean>>;
    public getCorrieriCaricabiliMagazzinoUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseGetCorrieriCaricabiliBean>>;
    public getCorrieriCaricabiliMagazzinoUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ResponseGetCorrieriCaricabiliBean>(`${this.basePath}/api/ibim/magazzino/getCorrieriCaricabiliMagazzino`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get corrieri caricabili produzione
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCorrieriCaricabiliProduzioneUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<ResponseGetCorrieriCaricabiliBean>;
    public getCorrieriCaricabiliProduzioneUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseGetCorrieriCaricabiliBean>>;
    public getCorrieriCaricabiliProduzioneUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseGetCorrieriCaricabiliBean>>;
    public getCorrieriCaricabiliProduzioneUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ResponseGetCorrieriCaricabiliBean>(`${this.basePath}/api/ibim/magazzino/getCorrieriCaricabiliProduzione`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get corrieri lavorabili
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCorrieriLavorabiliUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<ResponseGetCorrieriCaricabiliBean>;
    public getCorrieriLavorabiliUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseGetCorrieriCaricabiliBean>>;
    public getCorrieriLavorabiliUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseGetCorrieriCaricabiliBean>>;
    public getCorrieriLavorabiliUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ResponseGetCorrieriCaricabiliBean>(`${this.basePath}/api/ibim/magazzino/getCorrieriLavorabili`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get current damage
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentDamageUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<DamageBean>;
    public getCurrentDamageUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DamageBean>>;
    public getCurrentDamageUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DamageBean>>;
    public getCurrentDamageUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<DamageBean>(`${this.basePath}/api/ibim/magazzino/getCurrentDamage`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get fornitura amazon by id
     * 
     * @param idFornitura idFornitura
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFornituraAmazonByIdUsingPOST(idFornitura: number, observe?: 'body', reportProgress?: boolean): Observable<FornituraAmazonBean>;
    public getFornituraAmazonByIdUsingPOST(idFornitura: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FornituraAmazonBean>>;
    public getFornituraAmazonByIdUsingPOST(idFornitura: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FornituraAmazonBean>>;
    public getFornituraAmazonByIdUsingPOST(idFornitura: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idFornitura === null || idFornitura === undefined) {
            throw new Error('Required parameter idFornitura was null or undefined when calling getFornituraAmazonByIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<FornituraAmazonBean>(`${this.basePath}/api/ibim/magazzino/getFornituraAmazonById`,
            idFornitura,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get fornitura by id
     * 
     * @param idFornitura idFornitura
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFornituraByIdUsingPOST(idFornitura: number, observe?: 'body', reportProgress?: boolean): Observable<FornituraBean>;
    public getFornituraByIdUsingPOST(idFornitura: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FornituraBean>>;
    public getFornituraByIdUsingPOST(idFornitura: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FornituraBean>>;
    public getFornituraByIdUsingPOST(idFornitura: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idFornitura === null || idFornitura === undefined) {
            throw new Error('Required parameter idFornitura was null or undefined when calling getFornituraByIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<FornituraBean>(`${this.basePath}/api/ibim/magazzino/getFornituraById`,
            idFornitura,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get giacenza bancali by asin
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGiacenzaBancaliByAsinUsingPOST(bean: RequestBancaleBean, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getGiacenzaBancaliByAsinUsingPOST(bean: RequestBancaleBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getGiacenzaBancaliByAsinUsingPOST(bean: RequestBancaleBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getGiacenzaBancaliByAsinUsingPOST(bean: RequestBancaleBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getGiacenzaBancaliByAsinUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/api/ibim/magazzino/getGiacenzaBancaliByAsin`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get giacenza bancali flat
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGiacenzaBancaliFlatUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<BancaleFlatBean>>;
    public getGiacenzaBancaliFlatUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BancaleFlatBean>>>;
    public getGiacenzaBancaliFlatUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BancaleFlatBean>>>;
    public getGiacenzaBancaliFlatUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<BancaleFlatBean>>(`${this.basePath}/api/ibim/magazzino/getgiacenzaBancaliFlat`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get o2p by id collo
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getO2pByIdColloUsingPOST(bean: ColloBean, observe?: 'body', reportProgress?: boolean): Observable<Array<Orders2productBean>>;
    public getO2pByIdColloUsingPOST(bean: ColloBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Orders2productBean>>>;
    public getO2pByIdColloUsingPOST(bean: ColloBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Orders2productBean>>>;
    public getO2pByIdColloUsingPOST(bean: ColloBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getO2pByIdColloUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<Orders2productBean>>(`${this.basePath}/api/ibim/magazzino/getO2pByIdCollo`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get characteristics 2 property by prodtype
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProd2Char2PropByIdProdTypeUsingPOST(bean: RequestProd2CahrsBean, observe?: 'body', reportProgress?: boolean): Observable<Array<ResultProdType2Chars2propBean>>;
    public getProd2Char2PropByIdProdTypeUsingPOST(bean: RequestProd2CahrsBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResultProdType2Chars2propBean>>>;
    public getProd2Char2PropByIdProdTypeUsingPOST(bean: RequestProd2CahrsBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResultProdType2Chars2propBean>>>;
    public getProd2Char2PropByIdProdTypeUsingPOST(bean: RequestProd2CahrsBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getProd2Char2PropByIdProdTypeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ResultProdType2Chars2propBean>>(`${this.basePath}/api/ibim/magazzino/getProd2Char2PropByIdProducttype`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all producttype productclass
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProdTypeClassUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<ResultProdTypeClassBean>;
    public getProdTypeClassUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultProdTypeClassBean>>;
    public getProdTypeClassUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultProdTypeClassBean>>;
    public getProdTypeClassUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ResultProdTypeClassBean>(`${this.basePath}/api/ibim/magazzino/getAllProdTypeClass`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all producttype
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProducttypeUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<ProductTypeBean>>;
    public getProducttypeUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProductTypeBean>>>;
    public getProducttypeUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProductTypeBean>>>;
    public getProducttypeUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<ProductTypeBean>>(`${this.basePath}/api/ibim/magazzino/getAllProducttype`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get riepilogo bancali by viaggio
     * 
     * @param idViaggio idViaggio
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRiepilogoBancaliByViaggioUsingPOST(idViaggio: number, observe?: 'body', reportProgress?: boolean): Observable<Array<RiepilogoBancaliByViaggioBean>>;
    public getRiepilogoBancaliByViaggioUsingPOST(idViaggio: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RiepilogoBancaliByViaggioBean>>>;
    public getRiepilogoBancaliByViaggioUsingPOST(idViaggio: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RiepilogoBancaliByViaggioBean>>>;
    public getRiepilogoBancaliByViaggioUsingPOST(idViaggio: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idViaggio === null || idViaggio === undefined) {
            throw new Error('Required parameter idViaggio was null or undefined when calling getRiepilogoBancaliByViaggioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<RiepilogoBancaliByViaggioBean>>(`${this.basePath}/api/ibim/magazzino/getRiepilogoBancaliByViaggio`,
            idViaggio,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get stock 2 product by asin
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStock2productdByIdUsingPOST(bean: RequestStock2ProductdBean, observe?: 'body', reportProgress?: boolean): Observable<Stock2ProductdBean>;
    public getStock2productdByIdUsingPOST(bean: RequestStock2ProductdBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Stock2ProductdBean>>;
    public getStock2productdByIdUsingPOST(bean: RequestStock2ProductdBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Stock2ProductdBean>>;
    public getStock2productdByIdUsingPOST(bean: RequestStock2ProductdBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getStock2productdByIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Stock2ProductdBean>(`${this.basePath}/api/ibim/magazzino/getStock2productdById`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get warehouse outgoing products
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWarehouseOutogoingProductsByCorriereUsingPOST(bean: RequestOutgoingProductBean, observe?: 'body', reportProgress?: boolean): Observable<Array<ResultWarehouseOutgoingProductsBean>>;
    public getWarehouseOutogoingProductsByCorriereUsingPOST(bean: RequestOutgoingProductBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResultWarehouseOutgoingProductsBean>>>;
    public getWarehouseOutogoingProductsByCorriereUsingPOST(bean: RequestOutgoingProductBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResultWarehouseOutgoingProductsBean>>>;
    public getWarehouseOutogoingProductsByCorriereUsingPOST(bean: RequestOutgoingProductBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getWarehouseOutogoingProductsByCorriereUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ResultWarehouseOutgoingProductsBean>>(`${this.basePath}/api/ibim/magazzino/getWarehouseOutogoingProductsByCorriere`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get producttype 2 productclass 2 stock2product
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getproductType2productClass2Stock2productUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<ResultPTypePClassS2ProcuctBean>;
    public getproductType2productClass2Stock2productUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultPTypePClassS2ProcuctBean>>;
    public getproductType2productClass2Stock2productUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultPTypePClassS2ProcuctBean>>;
    public getproductType2productClass2Stock2productUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ResultPTypePClassS2ProcuctBean>(`${this.basePath}/api/ibim/magazzino/getproductType2productClass2Stock2product`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Print all binari label
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printAllBinarioLabelUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<BinarioBean>;
    public printAllBinarioLabelUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BinarioBean>>;
    public printAllBinarioLabelUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BinarioBean>>;
    public printAllBinarioLabelUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<BinarioBean>(`${this.basePath}/api/ibim/magazzino/printAllBinarioLabel`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Print bancale label
     * 
     * @param bancale bancale
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printBancaleLabelUsingPOST(bancale: BancaleBean, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public printBancaleLabelUsingPOST(bancale: BancaleBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public printBancaleLabelUsingPOST(bancale: BancaleBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public printBancaleLabelUsingPOST(bancale: BancaleBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bancale === null || bancale === undefined) {
            throw new Error('Required parameter bancale was null or undefined when calling printBancaleLabelUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ibim/magazzino/printBancaleLabel`,
            bancale,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Print binario label
     * 
     * @param binario binario
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printBinarioLabelUsingPOST(binario: BinarioBean, observe?: 'body', reportProgress?: boolean): Observable<BinarioBean>;
    public printBinarioLabelUsingPOST(binario: BinarioBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BinarioBean>>;
    public printBinarioLabelUsingPOST(binario: BinarioBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BinarioBean>>;
    public printBinarioLabelUsingPOST(binario: BinarioBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (binario === null || binario === undefined) {
            throw new Error('Required parameter binario was null or undefined when calling printBinarioLabelUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BinarioBean>(`${this.basePath}/api/ibim/magazzino/printBinarioLabel`,
            binario,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Print collo label new
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printColloLabelNewUsingPOST(bean: ColloBean, observe?: 'body', reportProgress?: boolean): Observable<PrintResultBean>;
    public printColloLabelNewUsingPOST(bean: ColloBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PrintResultBean>>;
    public printColloLabelNewUsingPOST(bean: ColloBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PrintResultBean>>;
    public printColloLabelNewUsingPOST(bean: ColloBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling printColloLabelNewUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PrintResultBean>(`${this.basePath}/api/ibim/magazzino/printColloLabelNew`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Print range bancali label
     * 
     * @param a a
     * @param b b
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printRangeBancaleLabelUsingPOST(a: number, b?: number, observe?: 'body', reportProgress?: boolean): Observable<BancaleBean>;
    public printRangeBancaleLabelUsingPOST(a: number, b?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BancaleBean>>;
    public printRangeBancaleLabelUsingPOST(a: number, b?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BancaleBean>>;
    public printRangeBancaleLabelUsingPOST(a: number, b?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (a === null || a === undefined) {
            throw new Error('Required parameter a was null or undefined when calling printRangeBancaleLabelUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (b !== undefined && b !== null) {
            queryParameters = queryParameters.set('b', <any>b);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BancaleBean>(`${this.basePath}/api/ibim/magazzino/printRangeBancaleLabel`,
            a,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Print range binari label
     * 
     * @param a a
     * @param b b
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printRangeBinarioLabelUsingPOST(a: number, b?: number, observe?: 'body', reportProgress?: boolean): Observable<BinarioBean>;
    public printRangeBinarioLabelUsingPOST(a: number, b?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BinarioBean>>;
    public printRangeBinarioLabelUsingPOST(a: number, b?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BinarioBean>>;
    public printRangeBinarioLabelUsingPOST(a: number, b?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (a === null || a === undefined) {
            throw new Error('Required parameter a was null or undefined when calling printRangeBinarioLabelUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (b !== undefined && b !== null) {
            queryParameters = queryParameters.set('b', <any>b);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BinarioBean>(`${this.basePath}/api/ibim/magazzino/printRangeBinarioLabel`,
            a,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rollback ordini non evadibili by idProducttype
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rollbackOrdiniByIdProducttypeUsingPOST(bean: RequestRollbackBean, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public rollbackOrdiniByIdProducttypeUsingPOST(bean: RequestRollbackBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public rollbackOrdiniByIdProducttypeUsingPOST(bean: RequestRollbackBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public rollbackOrdiniByIdProducttypeUsingPOST(bean: RequestRollbackBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling rollbackOrdiniByIdProducttypeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/ibim/magazzino/rollbackOrdiniByIdProducttype`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * save Bancale
     * 
     * @param bancale bancale
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveBancaleUsingPOST(bancale: BancaleBean, observe?: 'body', reportProgress?: boolean): Observable<ResultUpdateBancaleBean>;
    public saveBancaleUsingPOST(bancale: BancaleBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultUpdateBancaleBean>>;
    public saveBancaleUsingPOST(bancale: BancaleBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultUpdateBancaleBean>>;
    public saveBancaleUsingPOST(bancale: BancaleBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bancale === null || bancale === undefined ) {
            throw new Error('Required parameter bancale was null or undefined when calling saveBancaleUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResultUpdateBancaleBean>(`${this.basePath}/api/ibim/magazzino/saveBancale`,
            bancale,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * saveBinario
     * 
     * @param binario binario
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveBinarioUsingPOST(binario: BinarioBean, observe?: 'body', reportProgress?: boolean): Observable<BancaleBean>;
    public saveBinarioUsingPOST(binario: BinarioBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BancaleBean>>;
    public saveBinarioUsingPOST(binario: BinarioBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BancaleBean>>;
    public saveBinarioUsingPOST(binario: BinarioBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (binario === null || binario === undefined) {
            throw new Error('Required parameter binario was null or undefined when calling saveBinarioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BancaleBean>(`${this.basePath}/api/ibim/magazzino/saveBinario`,
            binario,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save damage collection
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveDamageCollectionUsingPOST(bean: RequestSaveDamageCollectionBean, observe?: 'body', reportProgress?: boolean): Observable<ResultUpdateDamageCollectionBean>;
    public saveDamageCollectionUsingPOST(bean: RequestSaveDamageCollectionBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultUpdateDamageCollectionBean>>;
    public saveDamageCollectionUsingPOST(bean: RequestSaveDamageCollectionBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultUpdateDamageCollectionBean>>;
    public saveDamageCollectionUsingPOST(bean: RequestSaveDamageCollectionBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling saveDamageCollectionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResultUpdateDamageCollectionBean>(`${this.basePath}/api/ibim/magazzino/saveDamageCollection`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save damage
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveDamageUsingPOST(bean: DamageBean, observe?: 'body', reportProgress?: boolean): Observable<DamageBean>;
    public saveDamageUsingPOST(bean: DamageBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DamageBean>>;
    public saveDamageUsingPOST(bean: DamageBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DamageBean>>;
    public saveDamageUsingPOST(bean: DamageBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling saveDamageUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DamageBean>(`${this.basePath}/api/ibim/magazzino/saveDamage`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * save Destinatario Forniture
     * 
     * @param bancale bancale
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveDestinatarioFornitureUsingPOST(bancale: DestinatarioFornituraBean, observe?: 'body', reportProgress?: boolean): Observable<DestinatarioFornituraBean>;
    public saveDestinatarioFornitureUsingPOST(bancale: DestinatarioFornituraBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DestinatarioFornituraBean>>;
    public saveDestinatarioFornitureUsingPOST(bancale: DestinatarioFornituraBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DestinatarioFornituraBean>>;
    public saveDestinatarioFornitureUsingPOST(bancale: DestinatarioFornituraBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bancale === null || bancale === undefined) {
            throw new Error('Required parameter bancale was null or undefined when calling saveDestinatarioFornitureUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DestinatarioFornituraBean>(`${this.basePath}/api/ibim/magazzino/saveDestinatarioForniture`,
            bancale,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * saveFornituraAmazon
     * 
     * @param fornitura fornitura
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveFornituraAmazonUsingPOST(fornitura: FornituraAmazonBean, observe?: 'body', reportProgress?: boolean): Observable<FornituraAmazonBean>;
    public saveFornituraAmazonUsingPOST(fornitura: FornituraAmazonBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FornituraAmazonBean>>;
    public saveFornituraAmazonUsingPOST(fornitura: FornituraAmazonBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FornituraAmazonBean>>;
    public saveFornituraAmazonUsingPOST(fornitura: FornituraAmazonBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fornitura === null || fornitura === undefined) {
            throw new Error('Required parameter fornitura was null or undefined when calling saveFornituraAmazonUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<FornituraAmazonBean>(`${this.basePath}/api/ibim/magazzino/saveFornituraAmazon`,
            fornitura,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * saveFornitura
     * 
     * @param fornitura fornitura
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveFornituraUsingPOST(fornitura: FornituraBean, observe?: 'body', reportProgress?: boolean): Observable<FornituraBean>;
    public saveFornituraUsingPOST(fornitura: FornituraBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FornituraBean>>;
    public saveFornituraUsingPOST(fornitura: FornituraBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FornituraBean>>;
    public saveFornituraUsingPOST(fornitura: FornituraBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fornitura === null || fornitura === undefined) {
            throw new Error('Required parameter fornitura was null or undefined when calling saveFornituraUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<FornituraBean>(`${this.basePath}/api/ibim/magazzino/saveFornitura`,
            fornitura,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * saveStock2Product
     * 
     * @param s2p s2p
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveStock2ProductUsingPOST(s2p: Stock2ProductdBean, observe?: 'body', reportProgress?: boolean): Observable<Stock2ProductdBean>;
    public saveStock2ProductUsingPOST(s2p: Stock2ProductdBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Stock2ProductdBean>>;
    public saveStock2ProductUsingPOST(s2p: Stock2ProductdBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Stock2ProductdBean>>;
    public saveStock2ProductUsingPOST(s2p: Stock2ProductdBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (s2p === null || s2p === undefined) {
            throw new Error('Required parameter s2p was null or undefined when calling saveStock2ProductUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Stock2ProductdBean>(`${this.basePath}/api/ibim/magazzino/saveStock2Product`,
            s2p,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save viaggio
     * 
     * @param viaggio viaggio
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveViaggioUsingPOST(viaggio: ViaggioBean, observe?: 'body', reportProgress?: boolean): Observable<ResultUpdateViaggioBean>;
    public saveViaggioUsingPOST(viaggio: ViaggioBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultUpdateViaggioBean>>;
    public saveViaggioUsingPOST(viaggio: ViaggioBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultUpdateViaggioBean>>;
    public saveViaggioUsingPOST(viaggio: ViaggioBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (viaggio === null || viaggio === undefined) {
            throw new Error('Required parameter viaggio was null or undefined when calling saveViaggioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResultUpdateViaggioBean>(`${this.basePath}/api/ibim/magazzino/saveViaggio`,
            viaggio,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Swap bancali
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public swapBancaliUsingPOST(bean: SwapBancaleBean, observe?: 'body', reportProgress?: boolean): Observable<SwapBancaleBean>;
    public swapBancaliUsingPOST(bean: SwapBancaleBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SwapBancaleBean>>;
    public swapBancaliUsingPOST(bean: SwapBancaleBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SwapBancaleBean>>;
    public swapBancaliUsingPOST(bean: SwapBancaleBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling swapBancaliUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SwapBancaleBean>(`${this.basePath}/api/ibim/magazzino/swapBancali`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update bancale e fornitura 2 stock amazon
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBancaleeFornitura2StockAmazonUsingPOST(bean: RequestUpdateBancalexF2sBean, observe?: 'body', reportProgress?: boolean): Observable<RequestUpdateBancalexF2sBean>;
    public updateBancaleeFornitura2StockAmazonUsingPOST(bean: RequestUpdateBancalexF2sBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RequestUpdateBancalexF2sBean>>;
    public updateBancaleeFornitura2StockAmazonUsingPOST(bean: RequestUpdateBancalexF2sBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RequestUpdateBancalexF2sBean>>;
    public updateBancaleeFornitura2StockAmazonUsingPOST(bean: RequestUpdateBancalexF2sBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling updateBancaleeFornitura2StockAmazonUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RequestUpdateBancalexF2sBean>(`${this.basePath}/api/ibim/magazzino/updateBancaleeFornitura2StockAmazon`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update fornitura 2 stock amazon
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFornitura2StockAmazonUsingPOST(bean: FornituraAmazon2stockBean, observe?: 'body', reportProgress?: boolean): Observable<FornituraAmazon2stockBean>;
    public updateFornitura2StockAmazonUsingPOST(bean: FornituraAmazon2stockBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FornituraAmazon2stockBean>>;
    public updateFornitura2StockAmazonUsingPOST(bean: FornituraAmazon2stockBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FornituraAmazon2stockBean>>;
    public updateFornitura2StockAmazonUsingPOST(bean: FornituraAmazon2stockBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling updateFornitura2StockAmazonUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<FornituraAmazon2stockBean>(`${this.basePath}/api/ibim/magazzino/updateFornitura2StockAmazon`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Get accessori da prelevare
     * 
     * @param tipologia tipologia
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public getAccessoriDaPrelevareUsingPOST(tipologia: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AccessorioBean>>;
        public getAccessoriDaPrelevareUsingPOST(tipologia: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AccessorioBean>>>;
        public getAccessoriDaPrelevareUsingPOST(tipologia: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AccessorioBean>>>;
        public getAccessoriDaPrelevareUsingPOST(tipologia: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
            if (tipologia === null || tipologia === undefined) {
                throw new Error('Required parameter tipologia was null or undefined when calling getAccessoriDaPrelevareUsingPOST.');
            }
    
            let headers = this.defaultHeaders;
    
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json'
            ];
            const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
            if (httpContentTypeSelected != undefined) {
                headers = headers.set('Content-Type', httpContentTypeSelected);
            }
    
            return this.httpClient.post<Array<AccessorioBean>>(`${this.basePath}/api/ibim/magazzino/accessoriDaPrelevare`,
                tipologia,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }

            /**
     * Get collo by barcode
     * 
     * @param barcode barcode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getColloByBarcodeUsingPOST(barcode: string, observe?: 'body', reportProgress?: boolean): Observable<ColloBean>;
    public getColloByBarcodeUsingPOST(barcode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ColloBean>>;
    public getColloByBarcodeUsingPOST(barcode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ColloBean>>;
    public getColloByBarcodeUsingPOST(barcode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (barcode === null || barcode === undefined) {
            throw new Error('Required parameter barcode was null or undefined when calling getColloByBarcodeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ColloBean>(`${this.basePath}/api/ibim/magazzino/getColloByBarcode`,
            barcode,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

