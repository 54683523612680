/**
 * F.R.I.D.A.Y.
 * Female Replacement Intelligent Digital Assistant Youth
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ColloBean } from '../model/colloBean';
import { ColloTrackerBean } from '../model/colloTrackerBean';
import { RequestColloByIdBean } from '../model/requestColloByIdBean';
import { RequestColloByIdOrderBean } from '../model/requestColloByIdOrderBean';
import { RequestConfermaCaricoColliBean } from '../model/requestConfermaCaricoColliBean';
import { RequestUpdateColloBean } from '../model/requestUpdateColloBean';
import { RequestUpdateColloTrackerBean } from '../model/requestUpdateColloTrackerBean';
import { ResultColli2PrintBean } from '../model/resultColli2PrintBean';
import { SospesoBean } from '../model/sospesoBean';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ColloControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Chiusura carico
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chiusuraCaricoUsingPOST(bean: RequestConfermaCaricoColliBean, observe?: 'body', reportProgress?: boolean): Observable<RequestConfermaCaricoColliBean>;
    public chiusuraCaricoUsingPOST(bean: RequestConfermaCaricoColliBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RequestConfermaCaricoColliBean>>;
    public chiusuraCaricoUsingPOST(bean: RequestConfermaCaricoColliBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RequestConfermaCaricoColliBean>>;
    public chiusuraCaricoUsingPOST(bean: RequestConfermaCaricoColliBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling chiusuraCaricoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RequestConfermaCaricoColliBean>(`${this.basePath}/api/ailime/collo/chiusuraCarico`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get colli to print
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getColli2PrintUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<ResultColli2PrintBean>>;
    public getColli2PrintUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResultColli2PrintBean>>>;
    public getColli2PrintUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResultColli2PrintBean>>>;
    public getColli2PrintUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<ResultColli2PrintBean>>(`${this.basePath}/api/ailime/collo/getColli2Print`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get colli by idOrder
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getColliByIdOrderUsingPOST(bean: RequestColloByIdOrderBean, observe?: 'body', reportProgress?: boolean): Observable<Array<ColloBean>>;
    public getColliByIdOrderUsingPOST(bean: RequestColloByIdOrderBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ColloBean>>>;
    public getColliByIdOrderUsingPOST(bean: RequestColloByIdOrderBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ColloBean>>>;
    public getColliByIdOrderUsingPOST(bean: RequestColloByIdOrderBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getColliByIdOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ColloBean>>(`${this.basePath}/api/ailime/collo/getColliByIdOrder`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get colli dpd produzione da ristampare
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getColliDpdDaRistampareUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<ColloBean>>;
    public getColliDpdDaRistampareUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ColloBean>>>;
    public getColliDpdDaRistampareUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ColloBean>>>;
    public getColliDpdDaRistampareUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<ColloBean>>(`${this.basePath}/api/ailime/collo/getColliDpdDaRistampare`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get colli prelevati
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getColliPrelevatiUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<ColloBean>>;
    public getColliPrelevatiUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ColloBean>>>;
    public getColliPrelevatiUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ColloBean>>>;
    public getColliPrelevatiUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<ColloBean>>(`${this.basePath}/api/ailime/collo/getColliPrelevati`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get collo by id
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getColloByIdUsingPOST(bean: RequestColloByIdBean, observe?: 'body', reportProgress?: boolean): Observable<ColloBean>;
    public getColloByIdUsingPOST(bean: RequestColloByIdBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ColloBean>>;
    public getColloByIdUsingPOST(bean: RequestColloByIdBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ColloBean>>;
    public getColloByIdUsingPOST(bean: RequestColloByIdBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getColloByIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ColloBean>(`${this.basePath}/api/ailime/collo/getColloById`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get sospesi
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSospesiUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<SospesoBean>>;
    public getSospesiUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SospesoBean>>>;
    public getSospesiUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SospesoBean>>>;
    public getSospesiUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<SospesoBean>>(`${this.basePath}/api/ailime/collo/getSospesi`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * test next val
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public testNextValUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<number>;
    public testNextValUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public testNextValUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public testNextValUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<number>(`${this.basePath}/api/ailime/collo/testNextVal`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Stato Collo Multiplo By Collo Tracker
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatoColloMultiploByColloTrackerUsingPOST(bean: RequestUpdateColloTrackerBean, observe?: 'body', reportProgress?: boolean): Observable<Array<ColloTrackerBean>>;
    public updateStatoColloMultiploByColloTrackerUsingPOST(bean: RequestUpdateColloTrackerBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ColloTrackerBean>>>;
    public updateStatoColloMultiploByColloTrackerUsingPOST(bean: RequestUpdateColloTrackerBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ColloTrackerBean>>>;
    public updateStatoColloMultiploByColloTrackerUsingPOST(bean: RequestUpdateColloTrackerBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling updateStatoColloMultiploByColloTrackerUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ColloTrackerBean>>(`${this.basePath}/api/ailime/collo/updateStatoColloMultiploByColloTracker`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Stato Collo Multiplo
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatoColloMultiploUsingPOST(bean: Array<RequestUpdateColloBean>, observe?: 'body', reportProgress?: boolean): Observable<Array<ColloBean>>;
    public updateStatoColloMultiploUsingPOST(bean: Array<RequestUpdateColloBean>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ColloBean>>>;
    public updateStatoColloMultiploUsingPOST(bean: Array<RequestUpdateColloBean>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ColloBean>>>;
    public updateStatoColloMultiploUsingPOST(bean: Array<RequestUpdateColloBean>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling updateStatoColloMultiploUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ColloBean>>(`${this.basePath}/api/ailime/collo/updateStatoColloMultiplo`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Stato Collo
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatoColloUsingPOST(bean: RequestUpdateColloBean, observe?: 'body', reportProgress?: boolean): Observable<ColloBean>;
    public updateStatoColloUsingPOST(bean: RequestUpdateColloBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ColloBean>>;
    public updateStatoColloUsingPOST(bean: RequestUpdateColloBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ColloBean>>;
    public updateStatoColloUsingPOST(bean: RequestUpdateColloBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling updateStatoColloUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ColloBean>(`${this.basePath}/api/ailime/collo/updateStatoCollo`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Find collo by id
     * 
     * @param idCollo idCollo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public findColloByIdUsingPOST(idCollo: number, observe?: 'body', reportProgress?: boolean): Observable<ColloBean>;
        public findColloByIdUsingPOST(idCollo: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ColloBean>>;
        public findColloByIdUsingPOST(idCollo: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ColloBean>>;
        public findColloByIdUsingPOST(idCollo: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
            if (idCollo === null || idCollo === undefined) {
                throw new Error('Required parameter idCollo was null or undefined when calling findColloByIdUsingPOST.');
            }
    
            let headers = this.defaultHeaders;
    
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json'
            ];
            const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
            if (httpContentTypeSelected != undefined) {
                headers = headers.set('Content-Type', httpContentTypeSelected);
            }
    
            return this.httpClient.post<ColloBean>(`${this.basePath}/api/ailime/collo/findColloById`,
                idCollo,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }

}
