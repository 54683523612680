/**
 * F.R.I.D.A.Y.
 * Female Replacement Intelligent Digital Assistant Youth
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ColloBean } from '../model/colloBean';
import { InputStreamResource } from '../model/inputStreamResource';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DownloadControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * download feed leroy merlin offerte
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadFeedLeroyMerlinOfferteUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadFeedLeroyMerlinOfferteUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadFeedLeroyMerlinOfferteUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadFeedLeroyMerlinOfferteUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<InputStreamResource>(`${this.basePath}/api/ailime/download/downloadFeedLeroyMerlinOfferte`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * download feed leroy merlin
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadFeedLeroyMerlinUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadFeedLeroyMerlinUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadFeedLeroyMerlinUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadFeedLeroyMerlinUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<InputStreamResource>(`${this.basePath}/api/ailime/download/downloadFeedLeroyMerlin`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * download segnacolli brt zpl
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadSegnacolliBrtZplUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadSegnacolliBrtZplUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadSegnacolliBrtZplUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadSegnacolliBrtZplUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<InputStreamResource>(`${this.basePath}/api/ailime/download/downloadSegnacolliBrtZpl`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * download segnacolli gls zpl
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadSegnacolliGlsZplUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadSegnacolliGlsZplUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadSegnacolliGlsZplUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadSegnacolliGlsZplUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<InputStreamResource>(`${this.basePath}/api/ailime/download/downloadSegnacolliGlsZpl`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * download segnacolli zpl
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadSegnacolliZplUsingPOST(bean: ColloBean, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadSegnacolliZplUsingPOST(bean: ColloBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadSegnacolliZplUsingPOST(bean: ColloBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadSegnacolliZplUsingPOST(bean: ColloBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling downloadSegnacolliZplUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InputStreamResource>(`${this.basePath}/api/ailime/download/downloadSegnacolliZpl`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Stampa accessori BRT
     * 
     * @param idCollo idCollo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadAccessoriBrtZplUsingGET(idCollo?: number, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public downloadAccessoriBrtZplUsingGET(idCollo?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public downloadAccessoriBrtZplUsingGET(idCollo?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public downloadAccessoriBrtZplUsingGET(idCollo?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idCollo !== undefined && idCollo !== null) {
            queryParameters = queryParameters.set('idCollo', <any>idCollo);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<InputStreamResource>(`${this.basePath}/api/ailime/download/stampaAccessoriBRT`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Stampa accessori GLS
     * 
     * @param idCollo idCollo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public downloadAccessoriGlsZplUsingGET(idCollo?: number, observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
        public downloadAccessoriGlsZplUsingGET(idCollo?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
        public downloadAccessoriGlsZplUsingGET(idCollo?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
        public downloadAccessoriGlsZplUsingGET(idCollo?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
    
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
            if (idCollo !== undefined && idCollo !== null) {
                queryParameters = queryParameters.set('idCollo', <any>idCollo);
            }
    
            let headers = this.defaultHeaders;
    
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json'
            ];
    
            return this.httpClient.get<InputStreamResource>(`${this.basePath}/api/ailime/download/stampaAccessoriGLS`,
                {
                    params: queryParameters,
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }

            /**
     * Stampa etichette tessili giornaliere
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stampaEtichtteTessiliGiornaliereUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public stampaEtichtteTessiliGiornaliereUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public stampaEtichtteTessiliGiornaliereUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public stampaEtichtteTessiliGiornaliereUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<InputStreamResource>(`${this.basePath}/api/ailime/download/stampaEtichetteTessiliGiornaliere`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
