import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/_services/authentication.service';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  role: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '', role: 'ROLE_ADMIN' },
  { path: '/report', title: 'Report', icon: 'summarize', class: '', role: 'ROLE_ADMIN' },
  { path: '/ordini', title: 'Ordini', icon: 'shopping_cart', class: '', role: 'ROLE_ADMIN, ROLE_LOGISTICA' },
  { path: '/servizi', title: 'Servizi', icon: 'miscellaneous_services', class: '', role: 'ROLE_LOGISTICA' },
  { path: '/archivio', title: 'Archivio', icon: 'inventory', class: '', role: 'ROLE_ASSISTENZA, ROLE_INVOICING, ROLE_LOGISTICA' },
  { path: '/carico', title: 'Carico', icon: 'local_shipping', class: '', role: 'ROLE_CARICO' },



  // { path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
  // { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
  // { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
  // { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
  //{ path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
  // { path: '/homepage', title: 'Home page',  icon:'home', class: '' },
  { path: '/gestione-utente', title: 'Gestione utenti', icon: 'account_circle', class: '', role: 'ROLE_ADMIN' },

  { path: '/catalogo', title: 'Catalogo', icon: 'menu_book', class: '', role: 'ROLE_ADMIN' },
  { path: '/clienti', title: 'Clienti', icon: 'supervisor_account', class: '', role: 'ROLE_ADMIN' },

  { path: '/ordini-magazzino', title:'Ordini magazzino', icon: 'dvr', class:'', role: 'ROLE_LOGISTICA, ROLE_INVOICING'},
  { path: '/magazzino', title: 'Magazzino IBIM', icon: 'swap_vertical_circle', class: '', role: 'ROLE_GESTIONE_MAGAZZINO' },
  { path: '/magazzino-ailime', title: 'Magazzino Ailime', icon: 'swap_vertical_circle', class: '', role: 'ROLE_GESTIONE_MAGAZZINO_AILIME, ROLE_INVOICING' },
  { path: '/evasione-produzione', title: 'Evasione produzione', icon: 'swap_vertical_circle', class: '', role: 'ROLE_ADMIN' },
  { path: '/produzione', title: 'Produzione', icon: 'factory', class: '', role:'ROLE_PRODUZIONE'},

  { path: '/prodotto', title: 'Prodotto', icon: 'king_bed', class: '', role: 'ROLE_ADMIN' },
  { path: '/spedizioni', title: 'Spedizioni', icon: 'local_shipping', class: '', role: 'ROLE_ADMIN' },
  { path: '/gestione-lotti', title: 'Gestione lotti', icon: 'ballot', class: ' ', role: 'ROLE_INVOICING' },
  
  { path: '/sospesi', title: 'Sospesi', icon: 'warning', class: ' ', role: 'ROLE_MARKETING, ROLE_LOGISTICA, ROLE_INVOICING' },
  
  { path: '/icons', title: 'Icons', icon: 'bubble_chart', class: '', role: 'ROLE_ADMIN' },
  { path: '/contrassegni', title: 'Contrassegni', icon: 'price_check', class: '', role: 'ROLE_INVOICING' },
  { path: '/rimborsi', title: 'Rimborsi', icon: 'money_off', class: '', role: 'ROLE_INVOICING' },
  { path: '/rientri', title: 'Rientri', icon: 'settings_backup_restore', class: '', role: 'ROLE_LOGISTICA' },
  { path: '/amministrazione', title: 'Amministrazione', icon: 'price_check', class:'', role: 'ROLE_INVOICING'},


  //{ path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    let currentRoles = this.authService.currentUserValue.authorities;
    this.menuItems = [];

    ROUTES.forEach(route => {
      if (route.role === 'ANY'
        || currentRoles.find(role => role === 'ROLE_ADMIN')
        || currentRoles.find(role => route.role.includes(role)))
        this.menuItems.push(route);
    })
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
}
